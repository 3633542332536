import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
}  from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators';
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private http: HttpClient) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (this.authService.isLoggedIn || this.authService.session()
      .pipe(
        tap(session => {
          if (session) {
            this.authService.user = session;
            // this.authService.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.authService.user.avatar + ')');
            this.authService.isLoggedIn = true;
            this.router.navigate(['/' + state.url]);
            return true;
          } else {
            this.authService.logout().subscribe(() => {
              this.router.navigate(['/login']);
              return false;
            });
          }
        })
      ));
  }
}
