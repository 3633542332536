import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import {FormArray, FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {isArray} from "util";
import {MatSnackBar, MatTableDataSource, MatPaginator, MatSort} from "@angular/material";
import {merge} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TasksService} from '../../services/tasks.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public columns: string[] = ['title', 'content', 'target', 'size', 'user.fullname', 'status', 'creation', 'download', 'actions'];
  public filterForm: FormGroup;
  public tasks: MatTableDataSource<any> = new MatTableDataSource<any>();
  public isLoading = false;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private fb: FormBuilder, public snackBar: MatSnackBar, private tasksService: TasksService) {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
    this.initPaginator();
    this.initEvents();
    this.loadFiles();
  }

  private initPaginator() {
    this.paginator.pageSizeOptions = [10, 25, 50, 100];
    this.paginator.pageSize = 10;
    this.paginator.length = 0;
  }

  private initEvents() {
    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
    );

    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
        this.loadFiles()
      }
    );
  }

  private loadFiles() {
    this.isLoading = true;

    let filter = this.filterForm.get('filter').value;
    let limit = this.paginator.pageSize;
    let page = this.paginator.pageIndex;

    let sort_column = null;
    let sort_direction = null;

    if (this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction;
    } else {
      sort_column = null;
      sort_direction = null;
    }

    this.tasksService.listTasks(page + 1, limit, sort_column, sort_direction, filter).subscribe(
      (data) => {
        console.log(data);
        this.paginator.length = data['count'];
        this.tasks.data = data['tasks'];
        this.isLoading = false;
      },
      () => {
        this.paginator.length = 0;
        this.tasks.data = [];
        this.isLoading = false;
      }
    );
  }

}
