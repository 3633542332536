import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";
import {Router, NavigationEnd, NavigationStart} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public indeterminate = 'indeterminate';
  public progress: boolean;

  constructor(public authService: AuthService, public router: Router) { }

  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.progress = true;
        }

        if (event instanceof NavigationEnd) {
          this.progress = false;
        }
      });
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(["/login"]);
    })
  }
}
