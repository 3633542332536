import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../services/auth/auth.service";
import { Router } from "@angular/router";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public isNotValidFields = true;
  public result = {login: '', password: ''};

  constructor( private fb: FormBuilder, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      login: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });
  }

  public connectMe = function () {
    this.authService.login(this.loginForm.get('login').value, this.loginForm.get('password').value)
      .subscribe(login => {
        if(this.authService.isLoggedIn){
          this.router.navigate(['/main']);
        }
      });
  }
}
