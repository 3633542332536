import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./components/login/login.component";
import { MainComponent } from "./components/main/main.component";
import { AuthGuardService } from "./services/auth/auth-guard.service";
// import {TasksCalendarComponent} from "./components/pages/tasks-calendar/tasks-calendar.component";
// import {SettingsCalendarComponent} from "./components/pages/settings-calendar/settings-calendar.component";
// import {UsersComponent} from "./components/pages/users/users.component";

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', canActivate: [ AuthGuardService ], component: MainComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'tasks-calendar', canActivate: [ AuthGuardService ], component: TasksCalendarComponent },
  // { path: 'settings-calendar', canActivate: [ AuthGuardService ], component: SettingsCalendarComponent },
  // { path: 'users', canActivate: [ AuthGuardService ], component: UsersComponent },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
