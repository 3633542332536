import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private readonly listTasksUrl = '/api/tasks/list/';

  constructor(public http: HttpClient) {
  }

  public listTasks(page: number, count: number, sortColumn: string, sortDirection: string, filter: string) {
    let params = new HttpParams().set('page', String(page)).set('count', String(count));

    if (sortColumn) {
      params = params.set('sort_column', sortColumn);

      if (sortDirection) {
        params = params.set('sort_direction', sortDirection);
      }
    }

    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http.get(this.listTasksUrl, { params: params });

  }
}
